import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 736.000000 736.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,736.000000) scale(0.100000,-0.100000)">



<path d="M0 3680 l0 -3680 3680 0 3680 0 0 3680 0 3680 -3680 0 -3680 0 0
-3680z m2055 1943 c-412 -53 -655 -313 -655 -703 0 -273 126 -619 346 -951
244 -369 596 -740 1034 -1090 601 -481 1253 -839 1825 -1004 409 -117 777
-135 1032 -50 201 67 346 197 408 366 13 35 24 56 24 46 1 -28 -48 -145 -84
-202 -37 -58 -124 -141 -189 -179 -536 -314 -1703 -1 -2721 729 -92 66 -105
72 -165 79 -100 10 -227 45 -305 82 -174 82 -304 268 -305 434 0 42 -4 50 -56
100 -96 94 -302 329 -404 460 -411 531 -587 1034 -489 1400 70 260 268 422
579 475 41 7 93 13 115 12 31 0 33 -1 10 -4z m1132 -1183 c263 -62 427 -232
458 -477 l7 -53 -209 0 -210 0 -12 39 c-14 48 -65 93 -124 111 -51 15 -150 15
-204 1 -157 -42 -207 -210 -84 -279 53 -29 99 -40 276 -62 88 -11 196 -29 240
-40 206 -52 316 -148 360 -315 19 -73 19 -213 1 -281 -14 -55 -61 -147 -82
-165 -15 -12 -48 9 -227 144 -91 70 -116 94 -110 106 15 33 8 90 -16 115 -34
36 -101 65 -168 72 -33 4 -72 15 -90 27 -45 30 -325 315 -424 432 -86 101
-187 233 -207 270 -15 28 35 118 104 187 171 169 444 232 721 168z m1691 -197
l-3 -197 -305 -1 c-168 -1 -313 -3 -322 -3 -16 -2 -18 -15 -18 -127 l0 -125
295 0 295 0 0 -195 0 -195 -295 0 -295 0 0 -155 0 -155 333 -2 332 -3 3 -192
2 -193 -491 0 -492 0 -64 37 -63 37 0 833 0 833 545 0 545 0 -2 -197z m1199
-1975 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
